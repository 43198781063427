export default {
  appTitlePrefix: 'dev ',
  firebaseConfig: {
    apiKey: 'AIzaSyBaZpMOao5sAL4wfzIYpvbUkLgG9YgQnQY',
    authDomain: 'deypuc75nnyb5.cloudfront.net',
    databaseURL: 'https://dev-media-admin.firebaseio.com',
    projectId: 'dev-media-admin',
    storageBucket: 'dev-media-admin.appspot.com',
    messagingSenderId: '174253916246',
    appId: '1:174253916246:web:ba03c3c1adfcb618',
  },
  googleOAuthClientID:
    '174253916246-9vpjll24tn29ac70l3iq2c11ro48i0ic.apps.googleusercontent.com',
};
